import Logo from "./images/logo.png";
import Community from "./images/community.png";
import Trust from "./images/trust.png";
import Strength from "./images/strength.png";
import X from "./icons/X.svg";
import Discord from "./icons/Discord.svg";
import Instagram from "./icons/Instagram.svg";
import Telegram from "./icons/Telegram.svg";
import LinkedIn from "./icons/LinkedIn.svg";
import Nairaland from "./icons/Nairaland.png";

const Assets = {
  Logo,
  X,
  Discord,
  Instagram,
  Telegram,
  LinkedIn,
  Nairaland,
  Community,
  Trust,
  Strength,
};

export default Assets;
