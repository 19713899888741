import React from "react";
import SocialLink from "./SocialLink";
import Assets from "../assets/Assets";
type Props = {
  header: boolean;
};
const Socials = ({ header }: Props) => {
  return (
    <div className="md:mt-20 mt-10">
      <p
        className={`text-center roboto ${
          header ? "text-white" : "text-[#621065] text-2xl roboto"
        } md:text-xl text-sm font-[600]`}
      >
        Stay Connected and Updated with us!
      </p>
      {!header ? (
        <p className="roboto text-[#333333] roboto text-center md:mb-20 mb-0 mt-2 font-[400] md:text-lg text-xs">
          Follow us on social media to stay updated with the latest news and
          activities from our community.
        </p>
      ) : null}

      <div className="flex items-center flex-wrap gap-5 justify-around mt-10">
        <SocialLink
          link="https://x.com/S27community"
          header={header}
          handle="S27community"
          icon={Assets.X}
        />
        <SocialLink
          link=" https://www.instagram.com/27_community"
          header={header}
          handle="27Community"
          icon={Assets.Instagram}
        />
        {/* <SocialLink header={header} handle="@handle" icon={Assets.LinkedIn} /> */}
        <SocialLink
          link="https://t.me/+tXIkY-sTMmJhM2M0"
          header={header}
          handle="27Community"
          icon={Assets.Telegram}
        />
        <SocialLink
          link="https://discord.gg/27community"
          header={header}
          handle="27Community"
          icon={Assets.Discord}
        />
        <SocialLink
          link="https://www.nairaland.com/27community"
          header={header}
          handle="27Community"
          icon={Assets.Nairaland}
        />
      </div>
    </div>
  );
};

export default Socials;
