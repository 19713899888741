import React from "react";
interface Props {
  icon: any;
  handle: string;
  header: boolean;
  link: string;
}
const SocialLink = ({ handle, icon, header, link }: Props) => {
  return (
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
      className="flex items-center gap-2"
    >
      <img src={icon} className="md:w-full w-5" alt="" />
      <p
        className={`md:text-lg roboto text-sm ${
          header ? "text-white" : "text-[#333333]"
        } font-[600] underline `}
      >
        @{handle}
      </p>
    </a>
  );
};

export default SocialLink;
