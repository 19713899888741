import Assets from "./assets/Assets";
import Socials from "./components/Socials";

const App = () => {
  return (
    <div className="">
      <div className="flex items-center xl:px-44 px-3 h-full justify-center clip py-10 bg-[#621065]">
        <div className="mb-32">
          <div className="flex items-center md:justify-center justify-start">
            <img
              src={Assets.Logo}
              className="md:w-[30rem] sm:w-[28rem] xs:w-[28rem]"
              alt=""
            />
          </div>
          <div className="lg:px-10 md:px-5 px-0">
            <div className="flex my-3">
              <div className="bg-[#FFFFFF1A] rounded-lg px-2 py-1">
                <p className="font-[600] text-white  sm:text-xs xs:text-xs">
                  How will we do that?
                </p>
              </div>
            </div>

            <div className="text-white font-[600] lg:text-4xl md:text-3xl text-lg md:text-center text-start">
              <span>By creating the </span>
              <span className="text-[#fd8b10] lg:text-6xl md:text-5xl text-2xl">
                best version
              </span>
              <span> of each of our members individually.</span>
            </div>
            <Socials header />
          </div>
        </div>
      </div>

      <div className="mt-10">
        <div className="grid md:grid-cols-2 grid-cols-1 md:gap-0 gap-3 ">
          <div className="flex items-center">
            <div className="xl:pl-32 px-3">
              <div className="xl:text-7xl lg:text-5xl text-3xl roboto font-[700]">
                <span className="text-[#fd8b10]">27</span>
                <span>COMMUNITY</span>
              </div>
              <p className="text-[#333333] inter font-[500] mt-3 lg:text-lg text-sm">
                ...was conceived by a team of seasoned professionals spanning
                diverse financial fields, including the stock market, marketing,
                cryptocurrencies, and more. Our primary mission is to foster the
                strongest and most trustworthy community globally. We're
                committed to supporting members in their pursuit of justice,
                goal achievement, and access to essential resources, recognizing
                the challenges individuals face alone.
              </p>
            </div>
          </div>
          <div className="xl:px-40 md:p-0 px-3">
            <img src={Assets.Community} alt="" />
          </div>
        </div>

        <div className="grid md:grid-cols-2 grid-cols-1 mt-10">
          <div className="md:order-none order-last md:p-0 p-3">
            <img src={Assets.Trust} alt="" />
          </div>
          <div className="xl:p-20 p-3 flex items-center">
            <p className="text-[#333333] font-[500] roboto mt-3 lg:text-lg text-sm">
              Trust is the bedrock of 27Community. New members have a unique
              opportunity to earn money without any upfront investment during
              their initial involvement. Setting us apart, this approach allows
              members to engage without financial risk. The founders provided
              the initial capital, enabling 27Community to support its members,
              who, in turn, contribute a percentage of their earnings to the
              27Community Wallet (27Wallet).
            </p>
          </div>
        </div>
        <div className="grid md:grid-cols-2 grid-cols-1">
          <div className="lg:p-20 p-3 flex items-center">
            <p className="text-[#333333] font-[500] roboto lg:text-xl text-sm mt-3">
              Our heartfelt ambition at 27Community is to become the strongest
              27Community in the world. Why? Because we're on a mission to
              create the largest network dedicated to delivering high quality
              education that's accessible to everyone.
            </p>
          </div>
          <div className="md:p-0 p-3">
            <img src={Assets.Strength} alt="" />
          </div>
        </div>
      </div>

      <div className="lg:my-40 my-10 lg:px-40 md:px-20 px-3">
        <Socials header={false} />
      </div>
    </div>
  );
};

export default App;
